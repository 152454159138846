import { Box, Typography } from "@mui/material";

const style = {
	background: "#EDDDFE",
	// display: "flex",

	padding: "4px 8px",
	borderRadius: "4px",
	// margin: "0",
	justifyContent: "center",
	alignContent: "center",
    paddingTop:0
};

export const ListCategory = ({ br, color_code, title, fontSize }) => {
	return (
		<Box
			component={"span"}
			
			sx={{
				...style,
				background: color_code,
				borderRadius: br,
				// paddingY:0
			}}
		>
			<Typography variant="smallText" sx={{fontSize:fontSize}}>{title}</Typography>
		</Box>
	);
};
ListCategory.defaultProps = {
	br: "4px",
	fontSize:"12px"
};
