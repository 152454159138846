import { FavoriteBorderOutlined } from "@mui/icons-material";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Slide,
  Stack,
  Typography,
  Modal,
  Tooltip,
} from "@mui/material";
import { cardStyle } from "../../styles/card";
import { ListCategory } from "../common/ListCategory";
import Image from "next/image";
import HeartOutlined from "assets/icon/heartoutlined.svg";
import HeartFilled from "assets/icon/heartfilled.svg";
import React, { useState, useRef, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import closeButtonIcon from "../../assets/icon/close_button.png";
import notepad from "../../assets/icon/notepad.png";
import { maxWidth } from "@mui/system";
import { useSession } from "next-auth/react";
import { ScrollRestorationContext } from "../../context/ScrollRestorationContext";
import { updateScroll } from "../../hooks/ScrollHandler";

export const FeaturedCurationCard = ({
  curation,

  curations = [],
  home = false,
  handleListFavorite,
  category = null,
  showTooltip = false,
}) => {
  const { pageDetails, setPageDetails, back, scrollContainerRef } = useContext(
    ScrollRestorationContext
  );
  const [favoriteImage, setFavoriteImage] = useState(HeartOutlined);

  const [clicked, setClicked] = useState(
    curation.user_list_favourites?.favourite
  );
  const style = cardStyle;
  const router = useRouter();

  const cardRef = useRef(null);
  const favIconRef = useRef(null);
  const tagRef = useRef([]);
  const [uniqueProducts, setUniqueProducts] = useState([]);

  useEffect(() => {
    let uniqueProductArray = curation?.products?.filter((obj, index, arr) => {
      return (
        arr.findIndex((item) => item.seller?.name === obj.seller?.name) ===
        index
      );
    });
    if (uniqueProductArray != null) {
      if (uniqueProductArray.length > 2) {
        uniqueProductArray = uniqueProductArray.slice(0, 2);
      }
      setUniqueProducts([...uniqueProductArray]);
    }
  }, []);

  const handleTagClick = (e, id) => {
    e.stopPropagation();
    router.push(`/category/${id}`);
  };
  const favoriteClickHandler = (e) => {
    e.stopPropagation();

    if (category != null) {
      handleListFavorite(curation.id, category);
    } else {
      handleListFavorite(curation.id);
    }
  };

  const handleCardClick = (e) => {
    e.stopPropagation();
    // updateScroll(router, curations, pageDetails, setPageDetails, back,scrollContainerRef.current.scrollTop);
    if (curation.list_type === "community_list") {
      router.push(`/curation/${curation.slug}`);
    } else {
      router.push(`/list/${curation.slug}`);
    }
  };

  return (
    <>
      <Card
        onClick={handleCardClick}
        sx={{
          ...style.card,
          cursor: "pointer",
          marginBottom: 2,
          boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
          position: "relative",
        }}
      >
        {" "}
        {showTooltip == true ? (
          <Tooltip
            open={true}
            title={
              <Typography variant="body2" color={"white"} sx={{ padding: 0.5 }}>
                Tap for products
              </Typography>
            }
            arrow
            PopperProps={{
              sx: {
                zIndex: 0,
                "& .MuiTooltip-tooltip": {
                  borderRadius: "10px",
                  backgroundColor: "#3AB6EB",
                },
                "& .MuiTooltip-arrow": {
                  color: "#3AB6EB",
                },
              },
            }}
            placement="top"
          >
            <Box
              sx={{
                width: "35px",
                position: "absolute",
                top: "11%",
                left: "75%",
              }}
            ></Box>
          </Tooltip>
        ) : null}
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography variant="body1" mb={1}>
                {curation?.title}
              </Typography>

              {!home ? (
                <Grid container spacing={1.5}>
                  {curation.list_category.length > 0 &&
                    curation.list_category.slice(0, 3).map((category, i) => {
                      return (
                        <Grid
                          onClick={(e) => handleTagClick(e, category.id)}
                          item
                          key={`category-${i}`}
                        >
                          <ListCategory
                            color_code={category.color_code}
                            title={category.name}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              ) : (
                <Box
                  sx={{
                    padding: 0.75,
                    border: "1px solid ",
                    borderColor: "grayColor.main",
                    borderRadius: "8px",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="smallText"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    Curated from
                    <Typography variant="smallText" ml={1} color={"primary"}>
                      {curation.playoff?.title}
                    </Typography>
                  </Typography>
                </Box>
              )}
              {/* </Stack> */}
              <Box>
                {uniqueProducts.map((product, i) => (
                  <Box
                    sx={{ display: "inline-block", margin: "8px 8px 0 0" }}
                    key={`seller-${i}`}
                  >
                    <ListCategory
                      color_code={"#EDDDFE"}
                      title={product.seller?.name}
                      br="8px"
                      fontSize="10px"
                    />
                  </Box>
                ))}
              </Box>
              <Box>
                {(curation.stylist_note !== null && curation.stylist_note!=="") ? (
                  <IconButton
                    aria-label="settings"
                    sx={{
                      height: "18px",
                      width: "19px",
                      marginTop: "11px",
                      marginLeft: "1px",
                    }}
                  >
                    <img
                      style={{ height: "18px", width: "19px" }}
                      src={notepad}
                    />
                  </IconButton>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Image
                priority
                alt={curation.title}
                src={curation.image?.file}
                width={160}
                height={160}
                sizes="(max-width: 768px) 100vw,(max-width: 1200px) 50vw, 33vw"
                style={{ height: "auto", width: "100%" }} //The point is right there!
                //OR className='w-100 h-100'
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardHeader
          sx={{
            textAlign: "left",
            "& .MuiCardHeader-avatar": {
              marginRight: "4px",
            },
            paddingTop: "0px",
            paddingRight: "25px",
            "& .MuiCardHeader-action": {
              margin: 0,
            },
          }}
          titleTypographyProps={{ variant: "body2" }}
          avatar={
            <Avatar
              sx={{ width: 20, height: 20, fontSize: 12 }}
              aria-label="profile"
              src={curation.owner?.image?.file}
            >
              {" "}
              {curation.owner?.pr ? (
                <Image
                  src={curation.owner?.image?.file}
                  width="20"
                  height="20"
                ></Image>
              ) : (
                curation.owner?.name.slice(0, 1)
              )}
            </Avatar>
          }
          action={
            <Stack direction="row">
              <Collapse orientation="horizontal" in={true}>
                <Typography
                  variant="body2"
                  color={"primary"}
                  sx={{ marginRight: "8px" }}
                >
                  {curation.favourites_count > 0
                    ? curation.favourites_count
                    : ""}
                </Typography>
              </Collapse>
              <IconButton
                aria-label="settings"
                sx={{ height: "18px", width: "19px" }}
                // ref={favIconRef}
                onClick={favoriteClickHandler}
              >
                <img
                  src={
                    curation.user_list_favourites?.favourite
                      ? HeartFilled
                      : HeartOutlined
                  }
                />
              </IconButton>
            </Stack>
          }
          title={curation.owner?.name}
        />
      </Card>
    </>
  );
};
